/** ***************************************************************************
 * Julia main script
 *************************************************************************** */

window.addEventListener('DOMContentLoaded', () => {
  const julia = {
    initializers: {},
    destroyers: {},
  };

  julia.start = function() {
    Object.entries(this.initializers).forEach(([key, init]) => init());
  };

  julia.destroy = function() {
    Object.entries(this.destroyers).forEach(([key, destroy]) => destroy());
  };

  julia.restart = function() {
    this.destroy();
    this.start();
  };

  window.JULIA = julia;

  window.dispatchEvent(new Event('julia:init'));

});
